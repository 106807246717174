import MapBoxGL from 'mapbox-gl';

import { FORMAT_FOR_USER, formatDate } from '@/services/JetDate';

// Utils
import Map from '@/utils/map';

const MAP_POPUP_TIMEOUT = 0;

let _mapPopup = null;

// Предыдущий запрос на формирования попапа
let _mapPopupPromise = null;

let _mapPopupTimeout = null;

// Контроллер для отправки сигнала на отмену запроса
// @see https://learn.javascript.ru/fetch-abort
const _cController = new AbortController();

// Создание попапа
const _makePopup = (id, lat, lon, speed, time, loadedTrack) => {
  if (_mapPopupTimeout !== null) {
    clearTimeout(_mapPopupTimeout);

    _mapPopupTimeout = null;
  }

  _mapPopupTimeout = setTimeout(() => {
    if (_mapPopupPromise !== null) {
      if (_mapPopup !== null) {
        _mapPopup.remove();
      }

      _cController.abort();
    }

    if (_mapPopup !== null) {
      _mapPopup.remove();
    }

    _mapPopupPromise = new Promise((resolve, reject) => {
      if (loadedTrack !== null) {
        if (_mapPopup !== null) {
          _mapPopup.remove();
        }

        const popup = _mapPopup !== null ? _mapPopup : new MapBoxGL.Popup({
          closeButton: false, // Скрываем стандартную кнопку закрытия
          maxWidth: '470px',
        });

        const className = 'v_popup_' + (new Date()).getTime();

        // let osm = {};
        // let lastActiveData = {};
        let vehicle = [];
        let vehicleData = [];

        (async () => {
          // const lastActive = await jet.http.post(`/api/publicApi?call=lastVehicle&arg.ids=${loadedTrack.points[0].deviceId}`);
          // lastActiveData = lastActive && Array.isArray(lastActive) && lastActive.length > 0 && lastActive[0] || {};
          // Информация о ТС
          // const vehicleResponse = await jet.http.post(`/rpc?d=jsonRpc`, {
          //   type: 'query',
          //   query: '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehicles',
          //   params: {
          //     tenantId: '00000000-0000-0000-0000-0000000000ff',
          //     // TODO: если передать vcId -> ничего не придет в ответ
          //     vcId: loadedTrack.vehicle.id || '00000000-0000-0000-0000-0000000000ff',
          //   },
          // });
          // // console.log(vehicleResponse, loadedTrack.points[0], loadedTrack, id);
          // vehicleData = vehicleResponse?.result?.data || [];
          // vehicle = vehicleData?.[0] || [];

          // Положение на карте
          // const osmUrl = 'https://nominatim.openstreetmap.org/reverse?format=json&zoom=18&addressdetails=1';
          // osm = await jet.http.get(`${osmUrl}&lat=${lat}&lon=${lon}`);
        })().then(() => {
          let regData = '';

          if (!!vehicleData[3]) {
            regData += `<br/>Маршрут № ${vehicleData[4]}`;

            if (!!vehicleData[11]) {
              const time1 = formatDate(new Date(vehicleData[13]), FORMAT_FOR_USER.ONLY_TIME);
              const time2 = formatDate(new Date(vehicleData[14]), FORMAT_FOR_USER.ONLY_TIME);

              regData += `, рейс № ${vehicleData[12]}: ${time1}-${time2}`;
            } else {
              regData += ` рейс не выполняется`;
            }
          }

          const navLonLat = `${parseFloat(lat).toFixed(5)} / ${parseFloat(lon).toFixed(5)}`;

          const status = speed && speed > 0
            ? `В движении, скорость ${parseInt(speed)} км/ч`
            : 'Стоит';

          popup.setLngLat({ lng: lon, lat: lat })
            .setHTML(`<div class="${className}" style="font-size: 12pt;">
              <div><b>${loadedTrack.vehicle.k} ${loadedTrack.vehicle.gov}</b></div>
              <br/>
              <div><b>Статус:</b> ${status}</div>
              <div><b>Время:</b> ${new Date(time).toLocaleString()}</div>
              <div><b>Перевозчик:</b> ${loadedTrack.vehicle.oname}</div>
              <div><b>Маршрут:</b> ${vehicle[9] || 'Отсутствует'} (${vehicle[10] || 'отсут.'})</div>
              <div><b>ш / д:</b> ${navLonLat}</div>

              <div>${regData}</div>
            </div>`)
            .addTo(Map.getMap());

          // <div>${osm.road || osm.display_name || 'Не удалось получить данные о улице'}</div>

          const sourceId = `source_points-${loadedTrack.track.id}`;
          const source = Map.getMap().getSource(sourceId);
          const sourceData = source._data;
          const index = (sourceData.features || []).findIndex(it => it.properties.id === id);

          if (index > -1) {
            const item = sourceData.features[index];
            item.properties.icon = 'arrow-direction-selected';

            sourceData.features.splice(index, 1, _copy(item));

            Map.createOrDataSource(sourceId, {
              type: 'FeatureCollection',
              features: sourceData.features,
            });
          }

          popup.on('close', () => {
            if (index > -1) {
              const item = sourceData.features[index];
              item.properties.icon = 'arrow-direction-blue';

              sourceData.features.splice(index, 1, _copy(item));

              Map.createOrDataSource(sourceId, {
                type: 'FeatureCollection',
                features: sourceData.features,
              });
            }
          });

          if (_mapPopup === null) {
            _mapPopup = popup;
          }

          resolve();
        });
      }

      _cController.signal.addEventListener('abort', reject);
    });

    _mapPopupPromise.finally(() => {
      _mapPopupPromise = null;

      clearTimeout(_mapPopupTimeout);
      _mapPopupTimeout = null;
    });
  }, MAP_POPUP_TIMEOUT);
};

/**
 * Миксин для компонентов с группировками
 */
export default {
  props: {
    // Массив ТС
    vehicles: {
      type: Array,
      required: true,
    },
    // Сгруппированные данные
    items: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    // Если нет данных, отобразится данная строка
    slotEmpty: {
      type: String,
      required: false,
      default: 'Нет данных для отображения',
    },
    // Индикатор что данные загружаются
    loadingData: {
      type: Boolean,
      required: false,
      default: false,
    },
    // Параметры фильтрации
    filter: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    // Текущий тип отображения компонента
    type: {
      type: String,
      required: false,
      default: 'group',
      validator: value => ['group', 'filter'].indexOf(value) !== -1,
    },
  },
  data: () => ({
    // Загрузка всех данных
    showAllLoading: false,
    // Выбор всех ТС
    show_all: false,
  }),
  mounted() {
    // TODO: проверять какой трек убрали
    Map.subscribe('on_track_hide', () => {
      if (_mapPopup !== null) {
        _mapPopup.remove();
      }
    });

    this.$root.$on(
      'journal_want_popup',
      (item) => {
        _makePopup(item.id, item.lat, item.lon, item.speed, item.time, this.getLoadedTrack_(item));
      },
    );

    Map.subscribe(
      'on_track_point_click',
      (data) => {
        _makePopup(
          data.props.id, data.props.lat, data.props.lon,
          data.props.speed, data.props.time, this.getLoadedTrack_(data.props),
        );
      },
    );
  },
  destroyed() {
    this.$root.$off('journal_want_popup');

    if (_mapPopupPromise !== null) {
      _cController.abort();

      _mapPopupPromise = null;
    }
  },
  methods: {
    clearMapPopup_() {
      if (_mapPopupPromise !== null) {
        if (_mapPopup !== null) {
          _mapPopup.remove();
        }

        _cController.abort();
      }

      if (_mapPopup !== null) {
        _mapPopup.remove();
      }
    },
    // Проверка на пустые данные
    emptyData(data) {
      return !data || (Array.isArray(data) && data.length === 0) || _emptyObject(data);
    },
    // Получение информации о выбранной машинке
    async _getVehicleInfo(properties) {
      try {
        // Последняя активность
        if (!properties.deviceId || properties.deviceId.length === 0) {
          return;
        }

        const lastActive = await jet.http.post(`/publicApi?call=lastVehicle&arg.ids=${properties.deviceId}`);
        const lastActiveData = lastActive && lastActive.length > 0 && lastActive[0] || {};

        // Информация о ТС
        const vehicleResponse = await jet.http.post(`/rpc?d=jsonRpc`, {
          type: 'query',
          query: '4ff9688e-277b-48a0-b211-b1e874e6f5f9.cbVehicles',
          params: {
            tenantId: '00000000-0000-0000-0000-0000000000ff',
            // TODO: если передать vcId -> ничего не придет в ответ
            vcId: properties.deviceId || '00000000-0000-0000-0000-0000000000ff',
          },
        });
        const vehicleData = vehicleResponse.result && vehicleResponse.result.data || [];
        const vehicle = vehicleData.length > 0 && vehicleData[0] || [];

        // Дополнительная информация
        // TODO: Получаю пустоту, поэтому ничего не выводится
        const info = await jet.http.post('/rpc?d=jsonRpc', {
          type: 'query',
          query: '948e1fa3-414a-4568-b4ed-3a5535b239e9.dsp_getDspInfoFn',
          params: {
            attr: 3,
            uid: properties.deviceId,
            id: properties.deviceId,
          },
        });
        // const infoResult = info && info.result && info.result.data;

        // Положение на карте
        // const osmUrl = 'https://nominatim.openstreetmap.org/reverse?format=json&zoom=18&addressdetails=1';
        // const osm = await jet.http.get(`${osmUrl}&lat=${lastActiveData.lat}&lon=${lastActiveData.lon}`);

        // Формирование переменных для постановки

        const title = `${vehicle[2]} ${vehicle[3]} ${vehicle[1]}`;

        const navStatus = (lastActiveData.speed && lastActiveData.speed > 0)
          ? `В движении, скорость ${parseInt(lastActiveData.speed)} км/ч`
          : 'Стоит';

        const navTime = !!lastActiveData.time
          ? new Date(lastActiveData.time).toLocaleString()
          : '-';

        const navLonLat = `${parseFloat(lastActiveData.lat).toFixed(5)} / ${parseFloat(lastActiveData.lon).toFixed(5)}`;

        let regData = '';

        if (!!vehicleData[3]) {
          regData += `<br/>Маршрут № ${vehicleData[4]}`;

          if (!!vehicleData[11]) {
            const time1 = $utils.formatDate(new Date(vehicleData[13]), 'HH:mm');
            const time2 = $utils.formatDate(new Date(vehicleData[14]), 'HH:mm');

            regData += `, рейс № ${vehicleData[12]}: ${time1}-${time2}`;
          } else {
            regData += ` рейс не выполняется`;
          }
        }

        const el = document.getElementById('cardPopupVehicle');

        return this._renderParams(el.innerHTML, {
          Title: title,
          NavStatus: navStatus,
          NavTime: navTime,
          NavLonLat: navLonLat,
          // OSM: osm.road || osm.display_name,
          RegData: regData,
          Carrier: vehicle[5] || 'Неизвестно',
          Route: `${vehicle[9] || 'Отсутствует'} (${vehicle[10] || 'отсут.'})`,
        });
      } catch (e) {
        console.error('Не удалось получить данные для попапа', e);

        jet.msg({
          text: 'Не удалось получить данные',
          color: 'warning',
        });

        return 'Не удалось получить данные! Попробуйте позднее';
      }
    },
    /**
     * Мини шаблонизатор
     * TODO: вынести куда-то в другое место, может в utils
     *
     * Принимемый объект @params должен содержать ключ и значение
     * Ключ преобразуется в [[key]] и подставится значение
     *
     * Важно! Ищется полное сопадение, поэтому пробелы ставить нельзя!
     *
     * @param {string} html Строка с html кодом для рендера
     * @param {Object} params Данные для шаблона
     * @returns {string}
     * @private
     */
    _renderParams: function(html, params) {
      Object.keys(params).forEach(key => {
        html = html.replace(`[[${key}]]`, params[key]);
      });

      return html;
    },
  },
};
